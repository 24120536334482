export const clickableWindow = (
  text: any,
  top: string,
  left: number,
  name: string
) => {
  const newDiv = document.createElement('div');
  newDiv.appendChild(text);

  newDiv.setAttribute('marked-button', name);
  newDiv.style.opacity = '0';
  newDiv.style.position = 'absolute';
  newDiv.style.padding = '4px 12px';
  newDiv.style.paddingRight = '23px';
  newDiv.style.backgroundColor = '#FACA15';
  newDiv.style.pointerEvents = 'none';
  newDiv.style.zIndex = '99';
  const topCount = parseFloat(top);
  newDiv.style.top = `${topCount - 4}px`;

  setTimeout(() => {
    newDiv.style.left = left - newDiv.offsetWidth + 'px';
    newDiv.style.opacity = 'initial';
  }, 0);

  return newDiv;
};

export const windowTitle = (text = '') => {
  const newText = document.createElement('p');

  newText.style.color = '#FFFFFF';
  newText.style.fontSize = '1rem';
  newText.style.fontWeight = '500';
  newText.style.textAlign = 'left';
  newText.style.letterSpacing = '1px';
  newText.style.margin = 'unset';
  newText.style.webkitTextFillColor = '#FFFFFF';
  newText.textContent = text;
  return newText;
};
