import { useMemo, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { HiExclamation } from 'react-icons/hi';
import { BreadCrumbs, Button, ModalBox } from '@pipedataai/storybook';

import { useAppSelector } from 'src/store/hooks';
import { SyncStatus } from 'src/store/services/google/types';
import { FilterCalendarDate } from 'src/store/services/create-campaign/types';
import {
  useGetCampainDataQuery,
  useLazyGetCampainDataQuery,
} from 'src/store/services/create-campaign/endpoints/create-campaign';
import { parseResponse } from 'src/store/services/helpers';

import { customToastMessage } from 'src/components/toast';
import EmptyList from 'src/components/empty-list';

import useChartData from 'src/features/detail-campaign/hooks/useChartData';
import SyncAccessModal from 'src/features/campaigns/blocks/sync-access-modal';
import DetailCampaign from 'src/features/detail-campaign/detail-campaign';
import useSaveAsDraft from 'src/features/create-campaign/hooks/useSaveAsDraft';
import useCampaignData from 'src/features/detail-campaign/hooks/useCampaignData';
import CompanyPreviewModal from 'src/features/create-campaign/blocks/company-preview-modal';
import CampaignHeader from 'src/features/detail-campaign/blocks/campaign-header';
import useSyncCamapaign from 'src/features/campaigns/hooks/useSyncCampaign';
import useStepDataTransform from 'src/features/create-campaign/hooks/useStepThreeDataTransform';

import useClearDataNavigate from 'src/helpers/useClearDataNavigate';

export function DetailCampaignPage() {
  const { id } = useParams();
  useGetCampainDataQuery(id as string, { skip: !id });
  const { handleNavigate } = useClearDataNavigate();
  const { isSyncing, handleRedirectPage } = useSyncCamapaign();
  const [openSyncModal, setOpenSyncModal] = useState(false);
  const [addId, setAddId] = useState('');
  const [previewType, setPreviewType] = useState('');
  const [isOpenCompanyPreviewModal, setIsOpenCompanyPreviewModal] =
    useState(false);
  const { filters } = useAppSelector((state) => state.createCampaignReducer);
  const { googleAdsData } = useStepDataTransform();
  const [getCampaignData] = useLazyGetCampainDataQuery();
  const { handlePreviewSave } = useSaveAsDraft();

  const today = useMemo(
    () => (filters.endDate ? new Date(filters.endDate) : new Date()),
    [filters.endDate]
  );
  const last_month = useMemo(
    () =>
      filters.startDate
        ? new Date(filters.startDate)
        : new Date().setDate(today.getDate() - 30),
    [filters.startDate, today]
  );

  const [filterParams, setFilterParams] = useState<FilterCalendarDate>({
    id: '',
    startDate: Intl.DateTimeFormat('sv-SE').format(last_month),
    endDate: Intl.DateTimeFormat('sv-SE').format(today),
    ads: filters.ads,
    version: filters.versionID,
    is_desktop: filters.is_desktop,
    part: filters.part,
  });

  const lastDay =
    filterParams.startDate || Intl.DateTimeFormat('sv-SE').format(last_month);
  const toDay =
    filterParams.endDate || Intl.DateTimeFormat('sv-SE').format(today);

  const {
    campaign,
    emptyCampaign,
    ads,
    campaignLoad,
    loadCampaignData,
    handleAddAdsStatistics,
  } = useCampaignData(lastDay, toDay, filterParams.version || '');
  const { chart, total, chartLoad, loadChartData } = useChartData(
    filters.part || '',
    filterParams,
    lastDay,
    toDay
  );

  const handleCreateCampaign = (url: string) => {
    if (SyncStatus[isSyncing] !== 'InProgress') {
      handleRedirectPage(url);
    } else {
      setOpenSyncModal(true);
    }
  };

  const handleEditCampaign = async (e: any, campaignID: string) => {
    const ctrlClicked = sessionStorage.getItem('ctrlDown');
    if (ctrlClicked === null || ctrlClicked !== 'true') {
      e.preventDefault();
      const { data, error } = await getCampaignData(campaignID);
      const { result, errorMessage } = parseResponse({ data, error });

      if (error && errorMessage) {
        customToastMessage(
          errorMessage,
          <HiExclamation className="h-5 w-5" />,
          'error',
          200
        );
      }
      if (result && !(SyncStatus[isSyncing] === 'InProgress')) {
        handleCreateCampaign(`/campaigns/update-campaign/${id}`);
      }
    }
  };

  const openPreview = ({ domain = '', ads_id = '', preview_type = '' }) => {
    const windowReference = window.open();
    handlePreviewSave(ads_id).then((isSaved) => {
      const previewAds = googleAdsData.find((item: any) => item.id === ads_id);
      const haveParams = previewAds?.url.includes('?');
      const url = encodeURI(
        previewAds?.url +
          `${
            haveParams ? '&' : '?'
          }pipe_preview=true&campaign_id=${id}&ad_id=${ads_id}&type=${preview_type}&domain=${domain}`
      );
      if (isSaved) {
        if (windowReference) windowReference.location = url;
      } else {
        window.close();
      }
    });
  };

  const handleOpenPreview = (id: string, preview_type: string) => {
    setAddId(id);
    setPreviewType(preview_type);
    if (preview_type === 'company') {
      setIsOpenCompanyPreviewModal(true);
      return;
    }
    openPreview({ ads_id: id, preview_type });
  };

  const handleCloseCompanyPreviewModal = (domain: string) => {
    setIsOpenCompanyPreviewModal(false);
    openPreview({ domain, preview_type: previewType, ads_id: addId });
  };

  useEffect(() => {
    window.document.onkeydown = (event) => {
      if (event.ctrlKey || event.metaKey) {
        sessionStorage.setItem('ctrlDown', 'true');
      }
    };
    window.document.onkeyup = (event) => {
      if (event.key === 'Control' || event.key === 'Meta') {
        sessionStorage.setItem('ctrlDown', 'false');
      }
    };
  }, []);

  useEffect(() => {
    if (id) {
      loadCampaignData(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id) {
      loadChartData(id, filterParams.part);
    }
  }, [filterParams.part, id, loadChartData]);

  useEffect(() => {
    if (chart) {
      handleAddAdsStatistics(chart);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chart]);

  return (
    <section className="flex flex-col h-auto py-6 px-10">
      <SyncAccessModal />
      <div className="mb-4">
        <BreadCrumbs
          items={[
            { title: 'Campaigns', link: '/campaigns' },
            {
              title: campaign?.name || emptyCampaign?.name || '',
              link: `/${id}`,
            },
          ]}
          onNavigateClick={handleNavigate}
        />
      </div>

      <CampaignHeader
        campaign={campaign}
        emptyCampaign={emptyCampaign}
        handleEditCampaign={handleEditCampaign}
      />

      {(emptyCampaign && ads.length <= 0 && !campaignLoad) ||
      (!campaign && !campaignLoad) ? (
        <EmptyList title="No data. <br/> Edit the campaign." />
      ) : (
        <>
          <DetailCampaign
            campaignID={id || ''}
            url={campaign ? campaign.page_url : ''}
            ads={ads || []}
            chartData={chart}
            total={total}
            filters={filterParams}
            setFilterParams={setFilterParams}
            handleOpenPreview={handleOpenPreview}
            lastCampaignDate={new Date(campaign ? campaign.created_at : '')}
            isLoading={campaignLoad}
            chartLoading={chartLoad}
            chartFilterType={filterParams.part}
          />

          <CompanyPreviewModal
            isOpen={isOpenCompanyPreviewModal}
            onClose={() => setIsOpenCompanyPreviewModal(false)}
            onSave={handleCloseCompanyPreviewModal}
            segmentID={campaign && campaign.segment_id}
          />
        </>
      )}

      <ModalBox
        isOpen={openSyncModal}
        className="[&>div>div]:py-6 [&>div>div]:px-6"
        onClose={() => setOpenSyncModal(false)}
      >
        <p className="mb-6 text-sm font-medium text-[#111928] text-center">
          Synchronization with Google Ads is in progress.
          <br />
          Wait for it to finish before creating a Personalization Campaign
        </p>
        <div className="flex justify-center">
          <Button
            baseType="outline"
            baseTitle="Ok"
            className="min-w-[100px] [&]:mr-0 justify-center"
            onClick={() => setOpenSyncModal(false)}
          />
        </div>
      </ModalBox>
    </section>
  );
}
