import { CustomTooltip, Dropdown, DropItem } from '@pipedataai/storybook';
import {
  CompanyPreviewIcon,
  FallbackPreviewIcon,
  InfoIcon,
} from '@pipedataai/storybook/dist/components/icons/icons';

import DetailAdCard from './blocks/ad-card';
import AdElements from './blocks/ad-elements';
import CRCard from './blocks/cr-card';
import TableHeadTitle from './blocks/table-head-title';
import RecommendationStatus from './blocks/recommendation';

export const TABLE_DETAIL_COLUMN = ({
  handleOpenPreview,
}: {
  handleOpenPreview: (adID: string, preview_type: string) => void;
}) => [
  {
    key: 'ad_copy',
    label: () => (
      <TableHeadTitle
        title="Ad copy"
        textPosition="center"
        className="py-4 flex justify-center normal-case whitespace-nowrap"
      />
    ),
    width: '8%',
    className: () => 'w-max py-7 px-[15px] text-center',
    render: (rowItems: any) => <DetailAdCard {...rowItems} />,
  },
  {
    key: 'pers_elements',
    label: () => (
      <TableHeadTitle
        title="Pers. Elements"
        textPosition="left"
        className="pl-2.5 py-4 flex justify-start normal-case whitespace-nowrap"
      />
    ),
    width: '14%',
    className: () => 'pl-2.5 py-4 text-left',
    render: (rowItems: any) =>
      rowItems.isPersonalized ? (
        <AdElements
          id={rowItems.id}
          elements={rowItems.elements}
          updated={rowItems.updated}
        />
      ) : (
        <p className="w-max py-[2px] px-1.5 bg-gray-100 font-medium text-xs text-gray-500 rounded-md">
          Not personalized
        </p>
      ),
  },
  {
    key: 'recommendation',
    label: () => (
      <div className="py-4 px-2.5 flex justify-start">
        <p className="text-xs font-semibold text-gray-700 normal-case">
          Recommendations
        </p>
        <CustomTooltip
          className="ml-1"
          hoverElement={<InfoIcon />}
          position="center"
        >
          <div className="max-w-[192px]">
            <p className="mb-1.5 leading-none text-sm font-medium text-gray-900 text-left normal-case">
              Recommendations
            </p>
            <p className="leading-tight text-xs font-normal text-gray-500 text-left normal-case">
              AI-driven insights based on data and results from past experiments
              and campaigns.{' '}
              <a
                href="https://pipedata.notion.site/RECS-Recommendations-e78ae837c33f4dae870fda06c782ae84"
                className="text-blue-500 underline"
              >
                Learn more.
              </a>
            </p>
          </div>
        </CustomTooltip>
      </div>
    ),
    width: '14%',
    className: () => 'py-7 pl-2.5',
    render: (rowItems: any) => <RecommendationStatus type={rowItems.type} />,
  },
  {
    key: 'visitors',
    label: () => (
      <TableHeadTitle
        title="Visitors"
        textPosition="center"
        className="w-full pl-4 pr-2.5 py-4 flex justify-end normal-case whitespace-nowrap"
        filterParam="visitors"
      />
    ),
    width: '8%',
    className: () => 'py-7 pl-2.5 text-right',
    render: (rowItems: any) => (
      <p className="text-xs font-medium text-gray-900 text-right">
        {rowItems.visitors}
      </p>
    ),
  },
  {
    key: 'cr_pers',
    label: () => (
      <TableHeadTitle
        title="CR (Pers.)"
        textPosition="center"
        className="w-full pl-4 pr-2.5 py-4 flex justify-end normal-case whitespace-nowrap"
        filterParam="crPers"
      />
    ),
    width: '8%',
    className: () => 'py-7 pl-2.5 text-right',
    render: (rowItems: any) => (
      <p className="text-xs font-medium text-gray-900 text-right">
        {rowItems.isPersonalized
          ? rowItems.crPers === 0 && rowItems.crCont === 0
            ? '-'
            : rowItems.crPers + '%'
          : '-'}
      </p>
    ),
  },
  {
    key: 'cr_cont',
    label: () => (
      <TableHeadTitle
        title="CR (Control)"
        textPosition="right"
        className="w-full pl-4 pr-2.5 py-4 flex justify-end normal-case whitespace-nowrap"
        filterParam="crCont"
      />
    ),
    width: '8%',
    className: () => 'py-7 px-2.5',
    render: (rowItems: any) => (
      <p className="text-xs font-medium text-gray-900 text-right">
        {rowItems.isPersonalized
          ? rowItems.crCont === 0 && rowItems.crPers === 0
            ? '-'
            : rowItems.crCont + '%'
          : '-'}
      </p>
    ),
  },
  {
    key: 'conversions_pers',
    label: () => (
      <TableHeadTitle
        title="Conv (Pers.)"
        textPosition="right"
        className="w-full pl-5 pr-2.5 py-4 flex justify-end normal-case whitespace-nowrap"
        filterParam="persLeads"
      />
    ),
    width: '8%',
    className: () => 'py-7 px-2.5',
    render: (rowItems: any) => (
      <p className="text-xs font-medium text-gray-900 text-right">
        {rowItems.persLeads}
      </p>
    ),
  },
  {
    key: 'conversions_control',
    label: () => (
      <TableHeadTitle
        title="Conv (Ctrl.)"
        textPosition="right"
        className="w-full pl-5 pr-2.5 py-4 flex justify-end normal-case whitespace-nowrap"
        filterParam="contLeads"
      />
    ),
    width: '8%',
    className: () => 'py-7 px-2.5',
    render: (rowItems: any) => (
      <p className="text-xs font-medium text-gray-900 text-right">
        {rowItems.contLeads}
      </p>
    ),
  },
  {
    key: 'conversions',
    label: () => (
      <TableHeadTitle
        title="Conv (All)"
        textPosition="right"
        className="w-full pl-6 pr-2.5 py-4 flex justify-end normal-case whitespace-nowrap"
        filterParam="leads"
      />
    ),
    width: '8%',
    className: () => 'py-7 px-2.5',
    render: (rowItems: any) => (
      <p className="text-xs font-medium text-gray-900 text-right">
        {rowItems.leads}
      </p>
    ),
  },
  {
    key: 'cr_lift',
    label: () => (
      <TableHeadTitle
        title="CR Lift"
        textPosition="right"
        className="w-full pl-6 pr-2.5 py-4 flex justify-end normal-case whitespace-nowrap"
        filterParam="crLift"
      />
    ),
    width: '8%',
    className: (rowItems: any) =>
      rowItems.isPersonalized
        ? rowItems.crPers > rowItems.crCont
          ? 'bg-green-100 py-7 px-2.5'
          : rowItems.crPers === rowItems.crCont
          ? 'py-7 px-2.5'
          : 'bg-red-100'
        : 'py-7 px-2.5',
    render: (rowItems: any) => {
      return rowItems.crPers === 0 && rowItems.crCont === 0 ? (
        <p className="text-xs font-medium text-gray-900 text-right">-</p>
      ) : (
        <CRCard
          percent={rowItems.crLift}
          isPersonalized={rowItems.isPersonalized}
          isPositive={rowItems.crPers > rowItems.crCont}
          isControlZero={rowItems.crCont === 0 || rowItems.crPers === 0}
        />
      );
    },
  },
  {
    key: 'preview',
    label: () => (
      <TableHeadTitle
        title="Preview"
        textPosition="center"
        className="w-full p-4 flex justify-center normal-case whitespace-nowrap"
      />
    ),
    width: '8%',
    className: () => 'py-7',
    render: (rowItems: any) => (
      <div className="flex justify-center">
        {rowItems.differentPreview ? (
          <Dropdown
            label={
              <div className="border-0 bg-inherit text-xs text-[#1C64F2]">
                Preview
              </div>
            }
            className="flex [&>div]:py-0"
          >
            <DropItem
              onClick={() => {
                handleOpenPreview(rowItems.id, 'fallback');
              }}
              className="py-2 text-xs"
              icon={<FallbackPreviewIcon className="mr-2" />}
            >
              Fallback Preview
            </DropItem>
            <DropItem
              onClick={() => {
                handleOpenPreview(rowItems.id, 'company');
              }}
              className="py-2 text-xs"
              icon={<CompanyPreviewIcon className="mr-2" />}
            >
              Company Preview
            </DropItem>
          </Dropdown>
        ) : (
          <button
            type="button"
            className="mb-1 border-0 bg-inherit text-xs text-[#1C64F2]"
            onClick={() => handleOpenPreview(rowItems.id, 'fallback')}
          >
            Preview
          </button>
        )}
      </div>
    ),
  },
];

export const TABLE_AD_ELEMENTS = [
  {
    key: 'element_name',
    label: () => (
      <TableHeadTitle
        className="py-1 px-2.5 normal-case"
        title="Elements"
        textPosition="left"
      />
    ),
    width: '20%',
    className: () => 'py-5 px-2.5 border-l',
    render: (rowItems: any) => (
      <p className="text-[10px] font-semibold text-gray-900">{rowItems.name}</p>
    ),
  },
  {
    key: 'default_value',
    label: () => (
      <TableHeadTitle
        className="py-1 px-2.5 normal-case"
        title="Control"
        textPosition="left"
      />
    ),
    width: '40%',
    className: () => 'py-5 px-2.5 border-l',
    render: (rowItems: any) => {
      return rowItems.element_type === 'image' ? (
        <a
          href={rowItems.default_value}
          target="_blank"
          className="text-[10px] font-normal text-gray-900 underline"
        >
          img1_control
        </a>
      ) : rowItems.element_type === 'button' ? (
        <div>
          <p className="text-[10px] font-normal text-gray-900">
            {rowItems.default_value}
          </p>
          {rowItems.default_url && (
            <a
              href={rowItems.default_url}
              target="_blank"
              className="text-[10px] font-normal text-gray-500 underline"
            >
              {rowItems.default_url}
            </a>
          )}
        </div>
      ) : (
        <p className="text-[10px] font-normal text-gray-900">
          {rowItems.default_value}
        </p>
      );
    },
  },
  {
    key: 'personalized_value',
    label: () => (
      <TableHeadTitle
        className="py-1 px-2.5 normal-case"
        title="Personalized"
        textPosition="left"
      />
    ),
    width: '40%',
    className: () => 'py-5 px-2.5 border-l',
    render: (rowItems: any) => {
      return rowItems.element_type === 'image' ? (
        <a
          href={rowItems.personalized_value}
          target="_blank"
          className="text-[10px] font-normal text-gray-900 underline"
        >
          img1_personalized
        </a>
      ) : rowItems.element_type === 'button' ? (
        <div>
          <p className="text-[10px] font-normal text-gray-900">
            {rowItems.personalized_value}
          </p>
          <a
            href={rowItems.destination_url}
            target="_blank"
            className="text-[10px] font-normal text-gray-500 underline"
          >
            {rowItems.destination_url}
          </a>
        </div>
      ) : (
        <p className="text-[10px] font-normal text-gray-900">
          {rowItems.personalized_value}
        </p>
      );
    },
  },
];

export const DEVICE_ITEMS = [
  {
    label: 'All',
    value: 'null',
  },
  {
    label: 'Desktop',
    value: 'true',
  },
  {
    label: 'Mobile',
    value: 'false',
  },
];
