import { ChangeEvent, useState } from 'react';
import { HiCheckCircle, HiCursorClick, HiExclamation } from 'react-icons/hi';
import { Button, Input, SimpleSelect } from '@pipedataai/storybook';

import { customToastMessage } from 'src/components/toast';

import { useAppDispatch } from 'src/store/hooks';
import { setMarkupLink } from 'src/store/slices/markup-editor-slice';
import { parseResponse } from 'src/store/services/helpers';
import {
  useStoreActionMutation,
  useUpdateActionMutation,
} from 'src/store/services/conversion-action/endpoints/conversion-action';
import {
  ConversionActionDTO,
  ConversionActionType,
  ConversionActionItem,
} from 'src/store/services/conversion-action/types';
import { VISIT_ITEMS } from '../constants';

interface IActionTypeProp {
  conversionActionType: ConversionActionType | undefined;
  selectedTag: string;
  editAction: ConversionActionItem | undefined;
  isExactly: boolean;
  pageUrl: string;
  actionName: string;
  onOpenEditor: () => void;
  onModalClose: () => void;
}

const isValidURL = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

const ActionsTypeForm = ({
  conversionActionType,
  selectedTag,
  editAction,
  isExactly,
  pageUrl,
  actionName,
  onOpenEditor,
  onModalClose,
}: IActionTypeProp) => {
  const dispatch = useAppDispatch();
  const [inputPageUrl, setInputPageUrl] = useState(pageUrl);
  const [isModalExactly, setModalExactly] = useState<boolean>(isExactly);
  const [modalActionName, setModalActionName] = useState<string>(actionName);

  const [storeAction, { isLoading: saveLoading }] = useStoreActionMutation();
  const [updateAction, { isLoading: updateLoading }] =
    useUpdateActionMutation();

  const saveData = async (dto: ConversionActionDTO) => {
    const response = await storeAction(dto);
    const { result, errorMessage, error } = parseResponse(response);
    if (error && errorMessage) {
      customToastMessage(
        errorMessage,
        <HiExclamation className="h-5 w-5" />,
        'error'
      );
      return;
    }
    if (result) {
      customToastMessage(
        result.message,
        <HiCheckCircle className="h-5 w-5" />,
        'success'
      );
      onModalClose();
    }
  };

  const updateData = async (dto: ConversionActionDTO) => {
    if (!editAction?.id) return;
    dto.id = parseInt(editAction?.id);
    const response = await updateAction(dto);
    const { result, errorMessage, error } = parseResponse(response);
    if (error && errorMessage) {
      customToastMessage(
        errorMessage,
        <HiExclamation className="h-5 w-5" />,
        'error'
      );
      return;
    }
    if (result) {
      customToastMessage(
        result.message,
        <HiCheckCircle className="h-5 w-5" />,
        'success'
      );
      onModalClose();
    }
  };

  const handleSaveAction = async () => {
    const validateUrl = isValidURL(inputPageUrl);
    if (validateUrl) {
      const dto = {
        name: modalActionName,
        action_type: conversionActionType,
        tag_path: selectedTag,
        page_url: inputPageUrl,
        is_exactly: isModalExactly,
      } as ConversionActionDTO;

      if (editAction) {
        await updateData(dto);
      } else {
        await saveData(dto);
      }
    } else {
      customToastMessage(
        inputPageUrl ? 'Invalid url' : "The 'URL' field must not be empty",
        <HiExclamation className="h-5 w-5" />,
        'error'
      );
    }
  };

  const handleOpenEditor = async () => {
    let href = '';
    try {
      href = new URL(inputPageUrl).href;
    } catch (e) {
      customToastMessage(
        'Invalid url',
        <HiExclamation className="h-5 w-5" />,
        'error'
      );
      return;
    }

    dispatch(setMarkupLink(href));
    onOpenEditor();
  };

  const handleEditUrl = (url: string) => {
    if (!url.startsWith('https')) {
      const correctUrl = url.replace('http:', 'https:');
      setInputPageUrl(correctUrl);
    } else {
      setInputPageUrl(url);
    }
  };

  return (
    <div className="flex gap-y-4 flex-col w-full">
      {ConversionActionType.Page_Visit == conversionActionType && (
        <p className="text-sm font-normal text-gray-700">
          Enter URL or condition that will trigger a conversion event when the
          page is visited. This is the best used for a “thank you” or
          “confirmation” pages that is displayed after submitting a form.
        </p>
      )}
      {ConversionActionType.Button_or_Link == conversionActionType && (
        <p className="text-sm font-normal text-gray-700">
          Enter the URL of the page which contains the element you want to track
          clicks on, then click &quot;Open Editor&quot; button.
          <br />
          Once the page loads, select the element(s) which triggers a successful
          conversion when clicked.
        </p>
      )}
      <Input
        id="conversionName"
        title="Conversion Action Name"
        className="[&>div>div]:max-w-none [&>div>div>input]:max-w-none [&>div>div>input]:p-2.5 [&>div>div>input]:bg-gray-50"
        value={modalActionName ?? ''}
        placeholder="e.g. Thank you page"
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setModalActionName(e.target.value)
        }
      />
      <div className="flex flex-col gap-y-3">
        <label
          htmlFor="formUrl"
          className="text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          {ConversionActionType.Form
            ? 'Enter the URL of the page containing the form whose submissions you want to track'
            : 'URL'}
        </label>
        <div className="flex">
          {ConversionActionType.Page_Visit == conversionActionType && (
            <SimpleSelect
              value={
                VISIT_ITEMS.find(
                  (item) => item.value === String(isModalExactly)
                )?.label || VISIT_ITEMS[0].label
              }
              onSelect={(e: any) => setModalExactly(e === 'true')}
              inputClassName="[&]:p-2.5 [&]:bg-gray-50 [&]:w-[100px] border [&]:border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 border-r-0 rounded-ee-none rounded-se-none"
              items={VISIT_ITEMS}
            />
          )}

          <div className="relative w-full">
            <input
              className={`block p-2.5 w-full z-20 text-sm text-gray-900
                           bg-gray-50 rounded-r-lg border border-gray-300
                           focus:ring-blue-500 focus:border-blue-500 ${
                             ConversionActionType.Page_Visit ==
                             conversionActionType
                               ? 'border-l-gray-50 border-l-0'
                               : 'rounded-lg'
                           }`}
              onChange={(e) => handleEditUrl(e.target.value)}
              value={inputPageUrl ?? ''}
              id="formUrl"
              name="url"
              placeholder="URL"
              type="url"
              required
            />
          </div>

          {ConversionActionType.Page_Visit !== conversionActionType && (
            <Button
              className="whitespace-nowrap ml-3 flex-row-reverse"
              baseType="default"
              baseTitle="Open Editor"
              onClick={handleOpenEditor}
              Icon={<HiCursorClick className="ml-2" />}
              disabled={inputPageUrl.length <= 10}
              reverse
            />
          )}
        </div>
      </div>
      <div>
        <Button
          baseType="default"
          baseTitle="Save"
          onClick={handleSaveAction}
          disabled={saveLoading || updateLoading}
          isLoading={saveLoading || updateLoading}
          reverse
        />
      </div>
    </div>
  );
};

export default ActionsTypeForm;
