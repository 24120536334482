import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  HiMenuAlt2,
  HiX,
  HiOutlineLogout,
  HiUserCircle,
  HiExclamation,
} from 'react-icons/hi';
import { Button, Dropdown, DropItem, ModalBox } from '@pipedataai/storybook';

import { parseResponse } from 'src/store/services/helpers';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
  useGetGoogleSignInValidateQuery,
  useLazyCheckIsSyncedGoogleQuery,
  useLazySyncGoogleAccountQuery,
} from 'src/store/services/google/endpoints/google';
import { useLogoutQueryMutation } from 'src/store/services/auth/endpoints/auth';
import { setSyncError, setSyncStatus } from 'src/store/slices/googleSyncSlice';
import { useGetAllUserDomainsQuery } from 'src/store/services/client-domains/endpoints/client-domains';

import { customToastMessage } from 'src/components/toast';

import useClearStates from 'src/helpers/useClearStates';

import { ISidebarProps } from './sidebar';
import AiGeneration from './ai-generation';
import SyncButton from './sync-button';

const LOGO_TOKEN = process.env.REACT_APP_LOGO_DEV_TOKEN;

const Header: React.FC<ISidebarProps> = ({ sidebarOpen, setSidebarOpen }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const innerRef = useRef<boolean>(false);
  const avatarRef = useRef<HTMLImageElement>(null);
  const [domain, setDomain] = useState('');
  const { clearedRun } = useClearStates();
  const [openSyncModal, setOpenSyncModal] = useState(false);
  const { user } = useAppSelector((state) => state.authReducer);
  const { isSyncing, syncError, latestSync, signInGoogle } = useAppSelector(
    (state) => state.googleSyncReducer
  );
  const { currentAds, totalAds } = useAppSelector(
    (state) => state.createCampaignReducer
  );
  const { domains } = useAppSelector((state) => state.clientDomainReducer);
  useGetAllUserDomainsQuery(undefined, { skip: domains.length > 0 });
  const { isLoading } = useGetGoogleSignInValidateQuery(undefined, {
    skip: signInGoogle,
  });
  const [checkIsSyncedGoogle, { isLoading: isLoadingCheck, isFetching }] =
    useLazyCheckIsSyncedGoogleQuery();
  const [logoutQuery, { error }] = useLogoutQueryMutation();
  const [syncGoogleAccount] = useLazySyncGoogleAccountQuery();

  const handleUserDomain = useCallback(() => {
    if (domains.length > 0) {
      const domainUrl = domains[0].url.replace(/^https?:\/\//, '');
      setDomain(domainUrl);
    }
  }, [domains]);

  const logoutHandler = async () => {
    const response = await logoutQuery();
    const { errorMessage } = parseResponse(response);
    if (error && errorMessage) {
      customToastMessage(
        errorMessage,
        <HiExclamation className="h-5 w-5" />,
        'error'
      );
    } else {
      clearedRun();
    }
  };

  const handleGoogleSync = async () => {
    dispatch(setSyncStatus(2));
    await syncGoogleAccount();
    checkIsSyncedGoogle();
    dispatch(setSyncError(undefined));
    setOpenSyncModal(false);
  };

  const handleOpenSyncModal = () => {
    setOpenSyncModal(true);
  };

  const handleCloseSyncModal = () => {
    setOpenSyncModal(false);
  };

  const handleChangeErrorImage = () => {
    if (avatarRef && avatarRef.current) {
      avatarRef.current.src = '/companies/company_1.png';
    }
  };

  useEffect(() => {
    if (!innerRef.current) {
      checkIsSyncedGoogle();
      innerRef.current = true;
    }
  }, [checkIsSyncedGoogle]);

  useEffect(() => {
    handleUserDomain();
  }, [domains, handleUserDomain]);

  return (
    <header className="h-16 flex justify-between items-center bg-white px-4 py-2 border-b border-gray-200">
      <div className="flex items-center">
        <button
          onClick={setSidebarOpen}
          className="mr-3 cursor-pointer rounded p-2 text-gray-600 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:inline"
        >
          <span className="sr-only">Toggle sidebar</span>
          {sidebarOpen && window && window.innerWidth < 1024 ? (
            <HiX className="h-6 w-6" />
          ) : (
            <HiMenuAlt2 className="h-6 w-6" />
          )}
        </button>
        <a href="/">
          <img
            alt="AI Pipedata logo"
            src="/assets/images/logo.svg"
            className="mr-3 h-6 sm:h-8"
          />
        </a>
      </div>
      {currentAds !== totalAds && (
        <div>
          <AiGeneration total={totalAds} current={currentAds} />
        </div>
      )}
      <div className="flex items-center lg:gap-3">
        {signInGoogle && (
          <SyncButton
            isSyncing={isSyncing}
            error={syncError}
            syncDate={latestSync}
            onSyncClick={handleOpenSyncModal}
            isLoading={isLoadingCheck || isLoading || isFetching}
          />
        )}
        <Dropdown
          className="[&]:h-8 [&]:w-8 [&>div]:py-0"
          label={
            <div className="h-8 w-8 flex items-center justify-center bg-gray-100 rounded-full overflow-hidden">
              <img
                ref={avatarRef}
                className="w-full h-full block object-cover"
                src={
                  domain
                    ? `https://img.logo.dev/${domain}?size=200&format=png&token=${LOGO_TOKEN}`
                    : '/companies/company_1.png'
                }
                alt="Client Avatar"
                onError={handleChangeErrorImage}
              />
            </div>
          }
        >
          <DropItem className="[&:hover]:bg-white py-2 px-4">
            <div className="flex flex-col items-start">
              <p className="truncate text-sm">{user?.username}</p>
              <p className="truncate text-sm font-medium text-gray-700">
                {user?.email}
              </p>
            </div>
          </DropItem>
          <DropItem
            onClick={(e) => {
              e.preventDefault();
              navigate('/account');
            }}
            className="border-t border-b border-gray-100"
          >
            <a
              className="flex items-center py-2 w-full text-left"
              href="/account"
            >
              <HiUserCircle className="mr-3" color="#6B7280" />
              <span className="text-sm font-normal text-gray-700">Account</span>
            </a>
          </DropItem>
          <DropItem onClick={logoutHandler} className="py-2 px-4">
            <HiOutlineLogout className="mr-3" color="#F05252" />
            <span className="text-sm font-normal text-red-500">Sign out</span>
          </DropItem>
        </Dropdown>
      </div>
      <ModalBox
        isOpen={openSyncModal}
        onClose={handleCloseSyncModal}
        windowClassName="max-w-md"
      >
        <ModalBox.Body>
          <p className="mb-6 text-sm font-medium text-[#111928] text-center">
            Start synchronization? Takes about 5 mins. No personalization edits
            during.
          </p>
          <div className="flex justify-end">
            <Button
              baseType="outline"
              baseTitle="Cancel"
              className="min-w-[100px] [&]:mr-2 justify-center"
              onClick={handleCloseSyncModal}
            />
            <Button
              baseType="default"
              baseTitle="Sync now"
              className="min-w-[100px] [&]:mr-0 justify-center"
              onClick={() => handleGoogleSync()}
            />
          </div>
        </ModalBox.Body>
      </ModalBox>
    </header>
  );
};

export default Header;
