import { checkElementsList, hasTextInChildren } from './getElementByHover';

export const handlePreSelectElement = (
  e: any,
  selectedElement: any,
  setElementSelect: (element: any) => void,
  byElements?: string[]
) => {
  const element = e.target;
  // Removing the selected element on a second click
  if (selectedElement && selectedElement.hasAttribute('pre-select')) {
    selectedElement.style.boxShadow = null;
    selectedElement.removeAttribute('pre-select');
    setElementSelect(null);
    return;
  }

  // Do nothing if the element is added
  if (selectedElement && selectedElement.hasAttribute('select')) {
    return;
  }
  // Selecting the active element
  if (selectedElement !== element) {
    const elementBackground = window
      .getComputedStyle(element)
      .getPropertyValue('background-image');
    const hasBackgroundImage = elementBackground.includes('url');

    const hasChildren = element.children.length > 0;
    const hasTextContent = hasTextInChildren(element, true);
    const actionElement =
      byElements && byElements?.length > 0
        ? byElements?.includes(element.tagName.toLowerCase())
        : false;

    if (hasBackgroundImage || !hasChildren || hasTextContent || actionElement) {
      if (byElements) {
        const checkElement = checkElementsList(byElements, element);
        if (checkElement && !byElements.includes('form')) {
          element.setAttribute('pre-select', 'true');
          element.style.boxShadow = 'inset 0px 0px 0px 4px #3F83F8';
          setElementSelect(element);
        }
        if (byElements.includes('form')) {
          const formInElement = element.querySelector('form');
          if (formInElement) {
            formInElement.setAttribute('pre-select', 'true');
            formInElement.style.boxShadow = 'inset 0px 0px 0px 4px #3F83F8';
            setElementSelect(formInElement);
          } else {
            const formOutElement = element.closest('form');
            formOutElement.setAttribute('pre-select', 'true');
            formOutElement.style.boxShadow = 'inset 0px 0px 0px 4px #3F83F8';
            setElementSelect(formOutElement);
          }
        }
      } else {
        element.setAttribute('pre-select', 'true');
        element.style.boxShadow = 'inset 0px 0px 0px 4px #3F83F8';
        setElementSelect(element);
      }
    }
  } else {
    setElementSelect(undefined);
  }
};
