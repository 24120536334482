export type GoogleSignIn = { url: string };

export type SyncGoogleAccount = { message: string };

export type FindGoogleToken = { code: string };

export type CheckGoogleSync = {
  company_id: number;
  error_text: string;
  id: number;
  job_part: 'all' | 'ads';
  job_type: 'google';
  start_time: string;
  status_code: SyncStatusKeys;
  update_time: string;
  user_id: number;
  is_ga_integrated: boolean;
};

export type GoogleAdsParams = {
  id: string;
  campaign_name: string;
  group_name: string;
};

export type GoogleAnalyticsConfig = {
  api_secret: string;
  measurement_id: string;
};

export const SyncStatus = {
  1: 'Queued',
  2: 'InProgress',
  3: 'Completed',
  4: 'CompletedWithError',
  10: 'Unknown',
};

export type SyncStatusKeys = keyof typeof SyncStatus;
